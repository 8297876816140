import React, { Component } from 'react';
import request from 'request-promise';
import './App.css';

class App extends Component {
  state = {
    serverurl: '',
    usuario: {
      nombre: '',
      usuario: '',
      pass: '',
      telefono: '',
      correo: '',
      eliminado: false,
      reportFrecuency: -1,
      municipio: 53,
      estado: 16,
    },
    unidad: {
      idUsuario: '',
      unidad: '',
      responsable: '',
      combustible: 12.0,
      imei: '',
      chip: '4435',
      motor: false,
      eliminado: 0,
      gpsModel: 'concox',
      alarmas: true,
    },
    editUser: true,
    editUnit: false,
    response: '',
  };

  handleServidor = (evt) => {
    const serverurl = evt.target.value;
    this.setState({ serverurl })
  };

  handleUsuario = name => (evt) => {
    const { usuario } = this.state;
    usuario[name] = evt.target.value;
    this.setState({ usuario });
  };

  handleUnidad = name => (evt) => {
    const { unidad } = this.state;
    unidad[name] = evt.target.value;
    this.setState({ unidad });
  };

  toggle = () => {
    const { editUser, editUnit } = this.state;
    this.setState({ editUser: !editUser, editUnit: !editUnit });
  };

  send = async () => {
    const {
      serverurl,
      usuario,
      unidad,
      editUser,
    } = this.state;
    const response = await request({
      method: 'POST',
      uri: serverurl,
      body: editUser ? usuario : unidad,
      json: true,
    })
      .catch(err => this.setState({ response: err }));
    if (response) {
      this.setState({ response });
    }
  };

  render = () => {
    const {
      response,
      editUser,
      editUnit,
      unidad,
      usuario,
      serverurl,
    } = this.state;
    return (
      <div className='App'>
        <input type='text' name='serverurl' id='serverurl' placeholder='server' value={serverurl} onChange={this.handleServidor} />
        <button onClick={this.toggle}>{editUser ? 'Usuario' : 'Unidad'}</button>
        {editUser &&
          <div className='form'>
            <input type='text' name='nombreUsuario' id='nombreUsuario' placeholder='nombre' value={usuario.nombre} onChange={this.handleUsuario('nombre')} />
            <input type='text' name='usuarioUsuario' id='usuarioUsuario' placeholder='usuario' value={usuario.usuario} onChange={this.handleUsuario('usuario')} />
            <input type='text' name='passUsuario' id='passUsuario' placeholder='pass' value={usuario.pass} onChange={this.handleUsuario('pass')} />
            <input type='number' name='telefonoUsuario' id='telefonoUsuario' placeholder='telefono' value={usuario.telefono} onChange={this.handleUsuario('telefono')} />
            <input type='email' name='correoUsuario' id='correoUsuario' placeholder='correo' value={usuario.correo} onChange={this.handleUsuario('correo')} />
          </div>
        }
        {editUnit &&
          <div className='form'>
            <input type='number' name='idUsuarioUnit' id='idUsuarioUnit' placeholder='idUsuario' value={unidad.idUsuario} onChange={this.handleUnidad('idUsuario')} />
            <input type='text' name='unidadUnit' id='unidadUnit' placeholder='unidad' value={unidad.unidad} onChange={this.handleUnidad('unidad')} />
            <input type='text' name='responsableUnit' id='responsableUnit' placeholder='responsable' value={unidad.responsable} onChange={this.handleUnidad('responsable')} />
            <input type='number' name='imeiUnit' id='imeiUnit' placeholder='imei' value={unidad.imei} onChange={this.handleUnidad('imei')} />
            <input type='number' name='chipUnit' id='chipUnit' placeholder='chip' value={unidad.chip} onChange={this.handleUnidad('chip')} />
            <input type='text' name='gpsModelUnit' id='gpsModelUnit' placeholder='gpsModel' value={unidad.gpsModel} onChange={this.handleUnidad('gpsModel')} />
          </div>
        }
        <button onClick={this.send}>Send</button>
        <textarea className='console' rows='10' type="text" name="response" id="response" value={JSON.stringify(response)} readOnly />
      </div>
    );
  }
}

export default App;
